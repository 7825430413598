import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Modal, Popover, Spin, Table, Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import {
  deleteUserById,
  getUsers,
  searchUser,
} from "../../../apis/settingsapi";
import { checkToken } from "../../../functions/functions";
import {
  ADD_USER,
  CELLNINJACOLOR,
  UPDATE_USER,
} from "../../../utils/constants";
import "./style.scss";
import UserForm from "./userForm";
import UserHistory from "./userHistory";

const Users = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [userKey, setUserKey] = useState();
  const [apiLoading, setAPILoading] = useState(true);
  const [specificUser, setSpecificUser] = useState([]);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const [userInfo, setUserInfo] = useState(false);
  const [userRecord, setUserRecord] = useState();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const addUsers = () => {
    setShowModal(true);
    setUserKey("Add");
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const fetchUsers = async () => {
    const response = await getUsers();
    if (response.status === 200) {
      setAPILoading(false);
      setUsers(response.data.sort((user1, user2) => user1.id - user2.id));
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditUser = (record) => {
    setSpecificUser(record);
    setShowModal(true);
    setUserKey("Edit");
  };

  const renderUsers = () => {
    return (
      <div>
        <header className="table-header">
          <h3>Showing All Users</h3>
          <div className="table_actions">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {permissions.map((permission) => {
                  return permission.name === ADD_USER ? (
                    <Tooltip title="Add User">
                      <Button
                        style={{ marginLeft: "10px" }}
                        type="text"
                        yy
                        onClick={addUsers}
                        icon={<UserAddOutlined />}
                      >
                        New User
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  );
                })}
              </div>
              <div style={{ width: "25%" }}>
                <Search
                  placeholder="Search Users"
                  onSearch={onSearch}
                  enterButton
                  allowClear
                />
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  };
  const onSearch = (value) => {
    if (value !== "") {
      searchUser(value).then((response) => {
        if (response.status === 200) {
          setUsers(response.data);
        }
      });
    } else fetchUsers();
  };

  const checkUserInfo = (record) => {
    if (record) {
      setUserRecord(record);
      setUserInfo(true);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (_, record) => {
        return (
          <div
            style={{ cursor: "pointer", fontWeight: "700" }}
            onClick={() => checkUserInfo(record)}
          >
            {record.id}
          </div>
        );
      },
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "center",
      key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Subscription Type",
      dataIndex: "userType",
      key: "userType",
      align: "center",
      sorter: (a, b) => a.userType.localeCompare(b.userType),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      align: "center",
      sorter: (a, b) => a.organization.localeCompare(b.organization),
    },
    {
      title: "Created On",
      dataIndex: "creationDate",
      key: "createdon",
      align: "center",
      sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
      render: (_, record) => {
        return (
          <React.Fragment>
            <span>
              {record.creationDate
                ? moment(record.creationDate).format("MM/DD/yyyy")
                : ""}
            </span>
            <br />
            <span>
              {record.creationDate
                ? moment(record.creationDate).format("hh:mm a")
                : ""}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      title: "Updated On",
      dataIndex: "updationDate",
      key: "updatedon",
      align: "center",
      sorter: (a, b) => a.updationDate.localeCompare(b.updationDate),
      render: (_, record) => {
        return (
          <React.Fragment>
            <span>
              {record.updationDate
                ? moment(record.updationDate).format("MM/DD/yyyy")
                : ""}
            </span>
            <br />
            <span>
              {record.updationDate
                ? moment(record.updationDate).format("hh:mm a")
                : ""}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      title: "Expire Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      align: "center",
      sorter: (a, b) => a.expiryDate.localeCompare(b.expiryDate),
      render: (_, record) => {
        return (
          <React.Fragment>
            <span>
              {record.expiryDate
                ? moment(record.expiryDate).format("MM/DD/YYYY")
                : ""}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      align: "center",
      sorter: (a, b) => a.enabled - b.enabled,
      render: (_, record) => {
        return (
          <React.Fragment>
            {record.enabled ? (
              <div>
                <span style={{ color: "blue", fontWeight: "bold" }} type="text">
                  Enabled
                </span>
              </div>
            ) : (
              <div>
                <span style={{ color: "red", fontWeight: "bold" }} type="text">
                  Disabled
                </span>
              </div>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: "Actions",
      width: "5%",
      dataIndex: "enabled",
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", width: "100%" }}>
            {permissions.map((permission) => {
              return permission.name === UPDATE_USER ? (
                <Popover content={() => content(record)}>
                  <Button
                    shape="round"
                    className="ant-button"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreVertIcon style={{ width: "16px" }} />
                  </Button>
                </Popover>
              ) : (
                ""
              );
            })}
          </div>
        );
      },
    },
  ];

  const content = (record) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          padding: "12px 16px",
        }}
      >
        <div>
          <Tooltip title="Edit User" placement="left">
            <Button
              style={{ marginLeft: "5px" }}
              type="default"
              shape="round"
              onClick={() => onEditUser(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete User" placement="left">
            <Button
              type="danger"
              onClick={(e) => {
                setDeleteUserId(record.id);
                setConfirmDeleteModal(true);
                e.stopPropagation();
              }}
              className="delete-project-ant-btn"
              icon={<DeleteOutlined style={{ color: "white" }} />}
              shape="round"
            ></Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const deleteUser = () => {
    deleteUserById(deleteUserId).then((response) => {
      if (response.status === 200) {
        fetchUsers();
      }
    });
  };

  return (
    <React.Fragment>
      <Spin
        spinning={apiLoading}
        style={{ color: CELLNINJACOLOR }}
        tip="Loading Users..."
        className="spin-loading"
      >
        <Table
          bordered
          dataSource={users}
          columns={columns}
          title={renderUsers}
          pagination={false}
          rowKey={"id"}
        />
      </Spin>
      <UserHistory
        user={userRecord}
        visible={userInfo}
        setVisible={(e) => {
          if (e === false) {
            setUserInfo(false);
            setUserRecord(null);
          }
        }}
      />
      <Modal open={showModal} onCancel={hideModal} title="Users" footer={null}>
        <UserForm
          action={userKey}
          user={specificUser}
          key={userKey}
          closeModal={() => setShowModal()}
          newUser={() => fetchUsers()}
        />
      </Modal>

      <div>
        <Modal
          style={{
            align: "center",
          }}
          title="Delete User"
          open={confirmDeleteModal}
          okButtonProps={{
            style: { backgroundColor: "#2980b9", borderColor: "#2980b9" },
          }}
          onOk={(e) => {
            deleteUser();
            setConfirmDeleteModal(false);
            setAPILoading(true);
            e.stopPropagation();
          }}
          onCancel={(e) => {
            setConfirmDeleteModal(false);
            e.stopPropagation();
          }}
          destroyOnClose
          autoFocus
        >
          <div style={{ display: "flex" }}>
            <div>
              <WarningOutlined
                style={{ fontSize: "45px", color: "rgb(220 220 5)" }}
              />
            </div>
            <div style={{ marginLeft: "4%", textAlignLast: "left" }}>
              <h3 style={{ margin: "0px" }}>
                Are you sure you want to delete this User?
              </h3>
              You cannot undo this action.
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default Users;
