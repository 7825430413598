import React, { useEffect, useState } from "react";
import "./ScreenTwo.scss";
import Dragger from "antd/lib/upload/Dragger";
import { Image, Input, Table, Popover } from "antd";
import {
  DeleteOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { dispatch } from "../../store";
import { saveSelfServiceData } from "../../actions/selfServiceEntityAction";
import { useSelector } from "react-redux";

import VERIZONVOICESAMPLE from "../../images/Verizon_Voice_Sample.png";
import VERIZONTEXTSAMPLE from "../../images/Verizon_Text_Sample.png";
import VERIZONIPSESSIONSSAMPLE from "../../images/Verizon_Ip_Sessions_Sample.png";
import VERIZONDEVICEIDSAMPLE from "../../images/Verizon_Device_Id_Sample.png";
import ATANDTSAMPLE from "../../images/at&tSampleFile.png";
import TMOBILEVOICESAMPLE from "../../images/T_Mobile_Voice_And_Text_Cdr_Sample.png";
import TMOBILEDATASAMPLE from "../../images/T_Mobile_Data_Cdr_Sample.png";
import ATANDTLOGO from "../../images/AT&T_logo.png";
import VERIZONLOGO from "../../images/Verizon_logo.png";
import TMOBILESPRINTLOGO from "../../images/TMobile_Sprint_logo.png";

const ScreenTwo = () => {
  const [verizonSample, setVerizonSample] = useState();
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [showSample, setShowSample] = useState("");
  const [showSamples, setShowSamples] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [previewReport, setPreviewReport] = useState(false);
  const [multiplePreview, setMultiplePreview] = useState(true);
  const [multipleCdrFiles, setMultipleCdrFiles] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [enableDragger, setEnableDragger] = useState(false);
  const [svOwnerNumber, setSvOwnerNumber] = useState("");
  const numRegex = new RegExp("^[0-9]{10,11}$");
  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );

  useEffect(() => {
    if (showSample === "Voice") setVerizonSample(VERIZONVOICESAMPLE);
    else if (showSample === "Text") setVerizonSample(VERIZONTEXTSAMPLE);
    else if (showSample === "IP-Sessions")
      setVerizonSample(VERIZONIPSESSIONSSAMPLE);
    else if (showSample === "Device-ID")
      setVerizonSample(VERIZONDEVICEIDSAMPLE);
    else if (showSample === "tmobileVoice")
      setVerizonSample(TMOBILEVOICESAMPLE);
    else if (showSample === "tmobileData") setVerizonSample(TMOBILEDATASAMPLE);
    else if ("at&t") setVerizonSample(ATANDTSAMPLE);
  }, [showSample]);

  const uploadMedia = async (file) => {
    dispatch(saveSelfServiceData("file", file));
    dispatch(saveSelfServiceData("fileName", file.name));
    if (getSelfServiceEntity.multipleCdrFiles)
      dispatch(saveSelfServiceData("uploadAnotherFile", true));
    setPreviewReport(false);
    if (!getSelfServiceEntity.key) {
      dispatch(saveSelfServiceData("key", crypto.randomUUID()));
    }
  };

  const deleteFiles = (type) => {
    let files = getSelfServiceEntity.filesToUpload?.filter(
      (file) => file.type !== type
    );
    getSelfServiceEntity.uploadedFiles[type] = "";
    getSelfServiceEntity.filesToUpload = files.length > 0 ? files : [];

    dispatch(saveSelfServiceData());
  };

  const deleteUploadedFile = (type) => {
    return <DeleteOutlined onClick={() => deleteFiles(type)} />;
  };

  const atandtFiles = () => {
    return (
      <div>
        <Image src={ATANDTLOGO} preview={false} />
        <br />
        <span>
          <ol className="after-file-uploaded">
            <li>Calls, text and Internet data</li>
            {getSelfServiceEntity.uploadedFiles.voice ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.voice}</span>{" "}
                <span className="delete-icon">
                  {deleteUploadedFile("voice")}
                </span>
              </span>
            ) : (
              ""
            )}
          </ol>
        </span>
      </div>
    );
  };

  const verizonMultipleFiles = () => {
    return (
      <div>
        <Image src={VERIZONLOGO} preview={false} />
        <br />
        <span>
          <ol className="after-file-uploaded">
            <li>Calls Records (Historical-3G)</li>
            {getSelfServiceEntity.uploadedFiles.voice3G ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.voice3G}</span>
                <span className="delete-icon">
                  {deleteUploadedFile("voice3G")}
                </span>
              </span>
            ) : (
              ""
            )}
            <li>Calls Records (4G-VoLTE)</li>
            {getSelfServiceEntity.uploadedFiles.voice4G ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.voice4G}</span>
                <span className="delete-icon">
                  {deleteUploadedFile("voice4G")}
                </span>
              </span>
            ) : (
              ""
            )}
            <li>Text message detail (Optional)</li>
            {getSelfServiceEntity.uploadedFiles.sms ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.sms}</span>
                <span className="delete-icon">{deleteUploadedFile("sms")}</span>
              </span>
            ) : (
              ""
            )}
            <li>MMS (Optional)</li>
            {getSelfServiceEntity.uploadedFiles.verizonMms ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.verizonMms}</span>
                <span className="delete-icon">
                  {deleteUploadedFile("verizonMms")}
                </span>
              </span>
            ) : (
              ""
            )}
            <li>Data use (Optional)</li>
            {getSelfServiceEntity.uploadedFiles.ipSessions ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.ipSessions}</span>
                <span className="delete-icon">
                  {deleteUploadedFile("ipSessions")}
                </span>
              </span>
            ) : (
              ""
            )}
          </ol>
        </span>
      </div>
    );
  };

  const tMobileMultipleFiles = () => {
    return (
      <div>
        <Image src={TMOBILESPRINTLOGO} preview={false} />
        <br />
        {getSelfServiceEntity?.towerError === "tower not found" ? (
          <div className="tower-text">
            Towers are missing. Upload Tower file to generate final report.
          </div>
        ) : (
          ""
        )}
        <span>
          <ol className="after-file-uploaded">
            <li className="mb">Calls Detail History With Voice and Text</li>
            {getSelfServiceEntity.uploadedFiles.voice ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.voice}</span>
                <span className="delete-icon">
                  {deleteUploadedFile("voice")}
                </span>
              </span>
            ) : (
              ""
            )}
            <li className="mb mr-to">Cell Towers (NEID File)</li>

            {getSelfServiceEntity.uploadedFiles.tower ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.tower}</span>
                <span className="delete-icon">
                  {deleteUploadedFile("tower")}
                </span>
              </span>
            ) : (
              ""
            )}
            <li className="mb mr-to">Data Sessions (Optional)</li>
            {getSelfServiceEntity.uploadedFiles.data ? (
              <span>
                <span className="bullet-tick red">✓</span>
                <span>{getSelfServiceEntity.uploadedFiles.data}</span>
                <span className="delete-icon">
                  {deleteUploadedFile("data")}
                </span>
              </span>
            ) : (
              ""
            )}
          </ol>
        </span>
      </div>
    );
  };

  const multipleCdrFile = () => {
    return (
      <div className="multiple-container">
        <br />
        <span className="uploaded-files-head">
          <span className="normal-head-spec">Y</span>OUR
          <span className="normal-head-spec"> C</span>ELLPHONE
          <span className="normal-head-spec"> F</span>ILES:
        </span>

        {}
        {getSelfServiceEntity.cdrType.includes("at&t") ? atandtFiles() : ""}
        {getSelfServiceEntity.cdrType.includes("verizon")
          ? verizonMultipleFiles()
          : ""}
        {getSelfServiceEntity.cdrType.includes("tMobile") ||
        getSelfServiceEntity.cdrType.includes("sprint")
          ? tMobileMultipleFiles()
          : ""}
      </div>
    );
  };

  const deleteFile = () => {
    dispatch(saveSelfServiceData("file", null));
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: uploadMedia,
    // onChange(info) {
    //   const { status } = info.file;
    //   if (status === "done") {
    //     message.success(`${info.file.name} File Uploaded Successfully`);
    //   } else if (status === "error") {
    //     message.error("Invalid File Type.");
    //   }
    // },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const getReportType = () => {
    if (getSelfServiceEntity.reportType === "basic") return "Basic";
    else if (getSelfServiceEntity.reportType === "premium") return "Premium";
    else return "Pro";
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "cellPhoneUserName",
      align: "center",
      key: "cellPhoneUserName",
    },
    {
      title: "Phone Number",
      dataIndex: "cellPhoneNumber",
      align: "center",
      key: "cellPhoneNumber",
    },
  ];

  useEffect(() => {
    if (getSelfServiceEntity.previewReport) {
      setPreviewReport(true);
      setUploadFile(true);
      setMultiplePreview(true);
    } else {
      setPreviewReport(false);
    }
    if (getSelfServiceEntity.cdrType) {
      setUploadFile(true);
      setMultiplePreview(false);
    }

    if (
      getSelfServiceEntity.multipleTMobileFiles ||
      getSelfServiceEntity.multipleCdrFiles
    ) {
      setUploadFile(true);
      setMultiplePreview(false);
    }
  }, [getSelfServiceEntity]);

  const previewCdrReport = () => {
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: "1.3rem",
          marginTop: "3rem",
        }}
      >
        <div
          className="bold"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Preview Changes
          <UploadOutlined
            className="upload-file"
            title="Upload Another File"
            onClick={() => {
              getSelfServiceEntity.previewReport = false;
              dispatch(saveSelfServiceData());
            }}
          />
        </div>
        <br />
        <br />
        <span className="bold">Cell Ninja Report Type: </span>
        {getReportType()}
        <br />
        <br />
        <span className="bold">Cell Phone File: </span>
        {getSelfServiceEntity.fileName}
        <br />
        <br />

        {getSelfServiceEntity.customizeReport.reportName === "" ? (
          ""
        ) : (
          <div>
            <span className="bold">Customize Report Name: </span>
            {getSelfServiceEntity.customizeReport.reportName}
          </div>
        )}
        <br />
        {getSelfServiceEntity.customizeReport.incidentDate === "" ? (
          ""
        ) : (
          <div>
            <span className="bold">Incident Date: </span>
            {getSelfServiceEntity.customizeReport.incidentDate}
          </div>
        )}
        <br />
        {getSelfServiceEntity.customizeReport.incidentTime === "" ? (
          ""
        ) : (
          <div>
            <span className="bold">Incident Time: </span>
            {getSelfServiceEntity.customizeReport.incidentTime}
          </div>
        )}
        <br />
        {getSelfServiceEntity.customizeReport.cellPhoneDetail.length > 0 ? (
          <div>
            <span className="bold">Cell Phone Details</span>
            <br />
            <br />
            <Table
              bordered
              dataSource={getSelfServiceEntity.customizeReport.cellPhoneDetail}
              columns={columns}
              pagination={false}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const uploadCdrDetails = () => {
    return (
      <div className="upload-container">
        <span className="outer-bulletHead">
          <span className="spec-bulletHead">T</span>HE
          <span className="spec-bulletHead"> F</span>ILES
          <span className="spec-bulletHead"> Y</span>OU'LL
          <span className="spec-bulletHead"> N</span>EED
          {/* <span className="spec-bulletHead"> V</span>ARY BY
          <span className="spec-bulletHead"> C</span>ELL
          <span className="spec-bulletHead"> P</span>HONE
          <span className="spec-bulletHead"> C</span>OMPANY: */}
        </span>
        <div className="bullet-container">
          <div className="bullet-it-I">
            <span className="bullet-f">AT&T</span>
            <ol className="bullets-texts">
              <li>
                <span className="bullet-main">
                  Calls, Texts and Internet Data
                  <span className="red-col">
                    <u>
                      <i
                        onClick={() => {
                          setShowSample("at&t");
                          setShowSamples(true);
                          setPreviewVisible(!isPreviewVisible);
                        }}
                      >
                        sample
                      </i>
                    </u>
                  </span>
                </span>
              </li>
            </ol>
          </div>
          <div className="bullet-it-II">
            <span className="bullet-f">Sprint/T-Mobile </span>
            <div className="more-lines-in-bullet">
              <ol className="bullets-texts">
                <li>
                  <span className="bullet-main">
                    Call Records
                    <span className="red-col">
                      <u>
                        <i
                          onClick={() => {
                            setShowSample("tmobileVoice");
                            setShowSamples(true);
                            setPreviewVisible(!isPreviewVisible);
                          }}
                        >
                          sample
                        </i>
                      </u>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="bullet-main">
                    Text Message Details (Optional)
                    <span className="red-col">
                      <u>
                        <i
                          onClick={() => {
                            setShowSample("tmobileVoice");
                            setShowSamples(true);
                            setPreviewVisible(!isPreviewVisible);
                          }}
                        >
                          sample
                        </i>
                      </u>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="bullet-main">
                    Internet Data (Optional)
                    <span className="red-col">
                      <u>
                        <i
                          onClick={() => {
                            setShowSample("tmobileData");
                            setShowSamples(true);
                            setPreviewVisible(!isPreviewVisible);
                          }}
                        >
                          sample
                        </i>
                      </u>
                    </span>
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="bullet-it-III">
            <span className="bullet-f">Verizon </span>
            <div className="more-lines-in-bullet">
              <ol className="bullets-texts">
                <li>
                  <span className="bullet-main">
                    Call Detail History With Voice and Text
                    <span className="red-col">
                      <u>
                        <i
                          onClick={() => {
                            setShowSample("Voice");
                            setShowSamples(true);
                            setPreviewVisible(!isPreviewVisible);
                          }}
                        >
                          sample
                        </i>
                      </u>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="bullet-main">
                    Cell Towers (“NEID” File)
                    <span className="red-col">
                      <u>
                        <i
                          onClick={() => {
                            setShowSample("Text");
                            setShowSamples(true);
                            setPreviewVisible(!isPreviewVisible);
                          }}
                        >
                          sample
                        </i>
                      </u>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="bullet-main">
                    Internet Data (Optional)
                    <span className="red-col">
                      <u>
                        <i
                          onClick={() => {
                            setShowSample("IP-Sessions");
                            setShowSamples(true);
                            setPreviewVisible(!isPreviewVisible);
                          }}
                        >
                          sample
                        </i>
                      </u>
                    </span>
                  </span>
                </li>
              </ol>
            </div>
          </div>

          <div className="bullet-it-IV">
            <div>
              <span className="bullet-it-iv-head">
                <span className="spec-bulletHead">F</span>OR
                <span className="spec-bulletHead"> S</span>PRINT
                <span className="spec-bulletHead"> O</span>R
                <span className="spec-bulletHead"> V</span>ERIZON
                <span className="spec-bulletHead"> R</span>ECORDS
              </span>
            </div>
            <div className="bullet-it-iv-inner">
              <div>
                <span className="phone-number-area-code">
                  Enter the cellphone user's area code and phone number here:
                </span>
              </div>
              <div className="owner-num-div">
                <Popover
                  className="popover"
                  destroyTooltipOnHide
                  title={() => popoverTitle()}
                  content={"Invalid Phone Number"}
                  trigger="click"
                  placement="top"
                  open={popoverOpen}
                  overlayStyle={{ width: "13rem" }}
                >
                  <Input
                    style={{ width: "190%" }}
                    type="tel"
                    placeholder="Cellphone user's phone number"
                    onChange={(e) => {
                      checkSvOwnerNumber(e);
                    }}
                  />
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <span className="upload-abv-pag mr-to m-font">
          <span className="upload-spec mh-font">U</span>PLOAD
          <span className="upload-spec mh-font"> Y</span>OUR
          <span className="upload-spec mh-font"> C</span>ALL
          <span className="upload-spec mh-font"> R</span>ECORD
          <span className="upload-spec mh-font"> F</span>ILE(S)
        </span>
      </div>
    );
  };

  const checkSvOwnerNumber = (e) => {
    const { value } = e.target;
    if (numRegex.test(value) || value.length === 0) {
      setEnableDragger(false);
      setPopoverOpen(false);
      getSelfServiceEntity.customizeReport.sprintVerizonOwnerNumber = value;
      dispatch(saveSelfServiceData());
      setSvOwnerNumber(value);
    } else {
      getSelfServiceEntity.customizeReport.sprintVerizonOwnerNumber = "";
      dispatch(saveSelfServiceData());
      setSvOwnerNumber("");
      setEnableDragger(true);
      setPopoverOpen(true);
    }
  };

  const popoverTitle = () => {
    return (
      <>
        <div className="popover-title">
          <div className="txt-color font">Error</div>
          <div>
            <CloseOutlined onClick={() => setPopoverOpen(false)} />
          </div>
        </div>
      </>
    );
  };

  const uploadCdrFile = () => {
    return (
      <div>
        <Dragger className="drag-doted" {...uploadProps}>
          <span className="upload-abv-pag dark">
            <span className="upload-spec dark">D</span>RAG
            <span className="upload-spec dark"> A</span>ND
            <span className="upload-spec dark"> D</span>ROP
            <span className="upload-spec dark"> S</span>ELECTED
            <span className="upload-spec dark"> C</span>ALL
            <span className="upload-spec dark"> F</span>ILE(S)
            <span className="upload-spec dark"> H</span>ERE
          </span>
        </Dragger>
      </div>
    );
  };
  return (
    <div className="main-con-two m-top">
      <div className="content-cont">
        {/* <div className="vol-I"> */}
        <div className="step-i mr num-btn num-btn-color ml">3</div>
        {/* </div> */}
        <div className="vol-II">
          <span className="big-head-normal">
            <span className="big-head-spec">U</span>PLOAD
            <span className="big-head-spec"> Y</span>OUR
            <span className="big-head-spec"> C</span>ELLPHONE
            <span className="big-head-spec"> F</span>ILES
            <span className="big-head-spec"> H</span>ERE
          </span>
          <br />
          <div className="inner-container">
            <span className="normal-head-norm">
              <span className="normal-head-spec">F</span>ILE
              <span className="normal-head-spec"> R</span>EQUIREMENTS
            </span>
            <br />
            <span className="descriptPgOne">
              We accept <b>original</b> call detail record files in{" "}
              <b>PDF, XLS,</b> or <b>CSV</b> formats. The files must include{" "}
              <b>cell tower locations</b> to determine the location and movement
              of a cell phone during use, and may contain <b>voice, text,</b>{" "}
              and <b>data</b> records.
            </span>
            <div hidden={uploadFile}>
              {uploadCdrDetails()}
              {/* {uploadCdrFile()} */}
            </div>
            <div hidden={multiplePreview}>
              {multipleCdrFile()}
              {/* {uploadCdrFile()} */}
            </div>

            {/* <div className="drag-doted">
            <span className="upload-abv-pag dark">
              <span className="upload-spec dark">D</span>RAG AND DROP SELECTE
              CALL FILE(S) HERE
            </span>
          </div> */}
            <Dragger
              className="drag-doted"
              disabled={enableDragger}
              {...uploadProps}
              onClick={() => {
                if (svOwnerNumber === "") {
                  getSelfServiceEntity.customizeReport.sprintVerizonOwnerNumber =
                    "";
                  dispatch(saveSelfServiceData());
                }
              }}
            >
              <span className="upload-abv-pag dark">
                <span className="upload-spec dark">D</span>RAG
                <span className="upload-spec dark"> A</span>ND
                <span className="upload-spec dark"> D</span>ROP
                <span className="upload-spec dark"> S</span>ELECTED
                <span className="upload-spec dark"> C</span>ALL
                <span className="upload-spec dark"> F</span>ILE(S)
                <span className="upload-spec dark"> H</span>ERE
              </span>
            </Dragger>
          </div>
        </div>
      </div>

      {showSamples ? (
        <div hidden={showSamples}>
          <Image
            onPreviewClose={() => setVerizonSample()}
            src={verizonSample}
            preview={{
              visible: isPreviewVisible,
              onVisibleChange: (visible, prevVisible) =>
                setPreviewVisible(visible),
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ScreenTwo;
