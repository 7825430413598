/* eslint-disable no-unused-vars */
import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetEntities } from "../../../actions/data";
import { changePassword } from "../../../apis/settingsapi";
import { clearAuth, getAuthToken } from "../../../utils/authToken";
import { CELLNINJACOLOR } from "../../../utils/constants";

const ChangePasswordForm = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [token, setToken] = useState(getAuthToken());
  const { currentUser = {} } = useSelector(({ app }) => app);
  useEffect(() => {
    setToken(getAuthToken());
  }, [token]);

  const onFinish = (values) => {
    values["username"] = currentUser.username;
    changePassword(values)
      .then((response) => {
        if (response.status === 200) {
          message.info(response.data.message, 5);
          clearAuth();
          dispatch(resetEntities());
          setToken(null);
          props.onSave();
          props.logout();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          message.error("Error: " + error.response.data.message);
        } else {
          message.error("An unexpected error occurred.");
        }
      });
  };

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 15,
    },
    form: form,
  };

  return (
    <Form
      {...layout}
      labelAlign="right"
      name="change_password"
      className="login-form"
      onFinish={onFinish}
      style={{ textAlignLast: "end" }}
    >
      <Form.Item
        style={{ textAlignLast: "auto" }}
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input password",
          },
          {
            pattern: "^(?=.*\\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*\\W.*).{10,}$",
            message:
              "Password must be at least 10 characters long, with one uppercase, one lowercase, and one special character.",
          },
        ]}
        hasFeedback
      >
        <Input.Password
          minLength={10}
          prefix={<LockOutlined className="site-form-item-icon" />}
        ></Input.Password>
      </Form.Item>
      <Form.Item
        style={{ textAlignLast: "auto" }}
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },

          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Passwords do not match!");
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password
          minLength={10}
          prefix={<LockOutlined className="site-form-item-icon" />}
        ></Input.Password>
      </Form.Item>
      <Form.Item noStyle>
        <Button
          type="danger"
          style={{ background: CELLNINJACOLOR, color: "white" }}
          htmlType="submit"
        >
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};
export default ChangePasswordForm;
