import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  chkTokenValidation,
  resetUserPassword,
} from "../../../apis/resetpasswordapi";
import ERRORICON from "../../../images/error-icon-cellninja-color.png";
import { CELLNINJACOLOR } from "../../../utils/constants";
import { fetchCustomerMessages } from "../../app/mainIndex";
import "./style.scss";

const ResetPass = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tokenSpin, setTokenSpin] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [token, setToken] = useState(null);

  const onFinish = (value) => {
    if (token) {
      setTokenSpin(true);
      resetUserPassword(value.password, token)
        .then((response) => {
          setTokenSpin(false);
          if (response.status === 200) {
            message.info(response.data.message, 5);
            navigate("/user/login");
            window.location.reload();
          }
          if (response?.response?.status === 500) {
            message.error("Error " + response.response.data.message);
          }
        })
        .catch((e) => {
          message.error(e.response.data.message, 5);
          setTokenSpin(false);
        });
    }
  };

  const checkTokenValidation = () => {
    if (token) {
      chkTokenValidation(token).then((response) => {
        if (response.status === 200) {
          if (response.data !== "") {
            if (moment(new Date()).isBefore(moment(response.data.expireTime))) {
              setTokenSpin(false);
            }
          } else {
            setTokenSpin(false);
            setErrorModal(true);
          }
        } else {
          console.log(response);
        }
      });
    }
  };

  useEffect(() => {
    setToken(window.location.href.split("/").pop());
    checkTokenValidation();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [token]);

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 15,
    },
    form: form,
  };

  return (
    <div className="main-reset-container">
      <div className="sub-con-reset">
        <div className="first">
          <span className="reset-span">
            <span className="reddish">Reset</span> Password
          </span>
        </div>
        <div className="second">
          <p className="fillin-span">
            Enter your new password below. Once confirmed, it will be activated.
          </p>
        </div>

        <div className="third">
          <Spin
            style={{ color: CELLNINJACOLOR }}
            spinning={tokenSpin}
            tip="Processing ..."
          >
            <Form
              {...layout}
              labelAlign="right"
              name="change_password"
              className="login-form"
              onFinish={onFinish}
              style={{ textAlignLast: "end" }}
            >
              <Form.Item
                className="fields"
                style={{ textAlignLast: "auto" }}
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input password",
                  },
                  {
                    pattern:
                      "^(?=.*\\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*\\W.*).{10,}$",
                    message: (
                      <div style={{ whiteSpace: "nowrap" }}>
                        Password must be at least 10 characters long, with one
                        uppercase, one lowercase, and one special character.
                      </div>
                    ),
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  minLength={10}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                ></Input.Password>
              </Form.Item>
              <Form.Item
                className="fields"
                style={{ textAlignLast: "auto" }}
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords do not match!");
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password
                  minLength={8}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                ></Input.Password>
              </Form.Item>
              <Form.Item className="fields" style={{ float: "right" }}>
                <Button
                  type="danger"
                  style={{ background: CELLNINJACOLOR, color: "white" }}
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>

      <div hidden={errorModal}>
        <Modal open={errorModal} width={500} closable={false} footer={null}>
          <div style={{ textAlign: "center" }}>
            <br />
            <img alt="" src={ERRORICON} height={80} width={80} />
          </div>

          <div style={{ textAlign: "center" }}>
            <br />
            <h2>Invalid Link!</h2>
            <br />
          </div>

          <div style={{ textAlign: "center", fontSize: "17px" }}>
            <h3>
              {fetchCustomerMessages()
                ? fetchCustomerMessages()["Invalid link for password reset"]
                : ""}
            </h3>
            <br />
          </div>

          <div style={{ textAlign: "center" }}>
            <a href="/user/login">
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setErrorModal(false);
                }}
              >
                Reset Password
              </Button>
            </a>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default ResetPass;
