import React from "react";
import "./ScreenOne.scss";
import PAY from "../../images/p&d.png";
// import CN_BETASEAL from "../../images/cellninja_beta_seal1.jpg";
import CN_BETASEAL from "../../images/cellninja_beta_seal.png";
import { useSelector } from "react-redux";

const ScreenOne = () => {
  const style = {
    display: "inline-grid",
    marginTop: "-60px",
    marginRight: "9em",
    marginLeft: "auto",
  };

  const forMobile = () => {
    return (
      <>
        <div className="grid-container">
          <div className="grid-item">
            <div className="step-i mr black-btn num-btn">1</div>
            {/* <div> */}
            <span className="nomrmal-D">
              <p className="pm">
                <span className="special-D">S</span>ELECT A
                <span className="special-D"> R</span>EPORT
                <span className="special-D"> T</span>YPE:
              </p>
              <p className="pm">
                <span className="special-D"> B</span>ASIC,{" "}
                <span className="special-D"> P</span>REMIUM OR
                <span className="special-D"> P</span>RO
              </p>
            </span>
            {/* </div> */}
          </div>
          <div className="grid-item">
            <div className="step-i mr black-btn num-btn">2</div>
            <span className="nomrmal-D mt">
              <p className="pm">
                <span className="special-D">C</span>USTOMIZE{" "}
                <span className="special-D">Y</span>OUR
                <span className="special-D"> R</span>EPORT
              </p>
              <p className="fs">(OPTIONAL)</p>
            </span>
          </div>
          <div className="grid-item">
            <div className="step-i mr black-btn num-btn">3</div>
            {/* <div> */}
            <span
              className="nomrmal-D mt"
              style={{ marginTop: "1rem !important" }}
            >
              <span className="special-D">U</span>PLOAD
              <span className="special-D"> Y</span>OUR
              <span className="special-D"> C</span>ELL
              <span className="special-D"> P</span>HONE
              <span className="special-D"> F</span>ILE
            </span>
            {/* </div> */}
          </div>
          <div className="grid-item">
            <div className="step-i mr black-btn num-btn">4</div>
            <div className="pdAlign">
              <span className="nomrmal-D">
                <span className="special-D">P</span>AY AND
                <span className="special-D"> D</span>OWNLOAD
                <span className="special-D"> Y</span>OUR
                <span className="special-D"> R</span>EPORT
              </span>
              <img src={PAY} className="payD" alt="" />
            </div>
          </div>
        </div>
      </>
    );
  };

  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );

  const forWeb = () => {
    return (
      <>
        <div
          className="outer"
          style={{
            paddingLeft: getSelfServiceEntity.page === 1 ? "6.5%" : "",
          }}
        >
          <div className="block">
            <div className="each-type">
              <div className="step-i mr btn-size-one black-btn">1</div>
              <div>
                <span className="nomrmal-D">
                  <p className="pm">
                    <span className="special-D">S</span>ELECT A
                    <span className="special-D"> R</span>EPORT
                    <span className="special-D"> T</span>YPE:
                  </p>
                  <p className="pm">
                    <span className="special-D"> B</span>ASIC,{" "}
                    <span className="special-D"> P</span>REMIUM OR
                    <span className="special-D"> P</span>RO
                  </p>
                </span>
              </div>
            </div>
            {/* <div style={{ height: "5rem" }}></div> */}
            <div className="each-type">
              <div className="step-i mr btn-size-one black-btn">3</div>
              <div>
                <span
                  className="nomrmal-D mt"
                  style={{ marginTop: "1rem !important" }}
                >
                  <span className="special-D">U</span>PLOAD
                  <span className="special-D"> Y</span>OUR
                  <span className="special-D"> C</span>ELL
                  <span className="special-D"> P</span>HONE
                  <span className="special-D"> F</span>ILE
                </span>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="each-type">
              <div
                className="step-i mr btn-size-one black-btn"
                // style={{ marginTop: "-6px" }}
              >
                2
              </div>
              <span className="nomrmal-D mt">
                <p className="pm">
                  <span className="special-D">C</span>USTOMIZE{" "}
                  <span className="special-D">Y</span>OUR
                  <span className="special-D"> R</span>EPORT
                </p>
                <p className="fs">(OPTIONAL)</p>
              </span>
            </div>
            {/* <div style={{ height: "5rem" }}></div> */}
            <div className="each-type">
              <div className="step-i mr btn-size-one black-btn">4</div>
              <div className="pdAlign">
                <span className="nomrmal-D">
                  <span className="special-D">P</span>AY AND{" "}
                  <span className="special-D"> D</span>OWNLOAD{" "}
                  <span className="special-D"> Y</span>OUR
                  <span className="special-D"> R</span>EPORT
                </span>
                <img src={PAY} className="payD" alt="" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="main-con-one">
      <div className="inner-con-one">
        <div
          style={{
            display: "flex",
            paddingLeft: "7%",
          }}
        >
          <div className="aligner-for-head">
            <span className="normalForOne">
              <span className="customForOne">C</span>REATE
              <span className="customForOne"> Y</span>OUR
              <span className="customForOne"> R</span>EPORT
              <span className="customForOne"> N</span>OW
            </span>
            <span className="head-Red">
              <i>FOUR EASY STEPS. CLICK NEXT TO BEGIN</i>
            </span>
          </div>
          <div className="testing-div">
            <img
              className="testing-image"
              alt=""
              src={CN_BETASEAL}
              // height={180}
              // width={190}
            />
          </div>
        </div>
        {forWeb()}
        {forMobile()}
      </div>
    </div>
  );
};

export default ScreenOne;
