import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilePdfTwoTone,
  FileZipTwoTone,
  RetweetOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  message,
  Modal,
  Popover,
  Pagination,
  Spin,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { downlaodFile } from "../../apis/projectapi";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  checkReportPayment,
  getSSRFilterCount,
  getSSRFilterData,
  getUserSSRCount,
  getUserSSRRecords,
} from "../../apis/ssr";
import { checkToken, downloadedFile } from "../../functions/functions";
import {
  BEN_USER_ID,
  BETA_USER_TYPE,
  CELLNINJACOLOR,
} from "../../utils/constants";
import StripComponent from "../stripe/App";
import "./style.scss";
import { fetchCustomerMessages } from "../app/mainIndex";
import DOWNLOADICON from "../../images/downloading-icon-cloud.png";
import ALERTICON from "../../images/alert-icon.png";
import DOLLARICON from "../../images/dollar-icon.png";

// This file is for User Dashboard.
const UserReports = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [genratingFile, setGenratingFile] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [formValues, setFormValues] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [ssrCount, setSSRCount] = useState();
  const [sortBy, setSortBy] = useState("date");
  const [orderBy, setOrderBy] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(false);
  const [betaUserExpired, setBetaUserExpired] = useState(false);
  const [recordId, setRecordId] = useState();
  const [fileName, setFileName] = useState();
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadCompletionModal, setDownloadCompletionModal] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    loadSSRCount();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const loadSSRCount = (
    pageNumber = 1,
    recordsPerPage = 10,
    sortBy = "creationDate",
    orderBy = "descend"
  ) => {
    setLoading(true);
    getUserSSRCount().then((res) => {
      if (res.status === 200) {
        setSSRCount(res.data);
        loadSSRRecords(pageNumber, recordsPerPage);
      }
    });
  };

  const loadSSRRecords = (current, pageSize) => {
    getUserSSRRecords(current, pageSize)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onShowSizeChange = (pageNumber, recordsPerPage) => {
    setPageNumber(pageNumber);
    setRecordsPerPage(recordsPerPage);
    loadSSRRecords(pageNumber, recordsPerPage, sortBy, orderBy);
    if (formValues === undefined)
      loadSSRRecords(pageNumber, recordsPerPage, sortBy, orderBy);
    else onSearch(pageNumber, recordsPerPage, formValues);
  };

  const generateFinalReport = (record, analysisReport) => {
    if (record) {
      let fileName;
      if (
        record.outputPath.split("\\").pop().split("_").pop() ===
        "MultipleVerizonFiles.pdf"
      ) {
        fileName =
          "Final Report_" +
          record.outputPath.split("\\").pop().split("_").pop();
      } else if (
        record.outputPath.split("\\").pop().split("_").pop() ===
        "MultipleTMobileFiles.pdf"
      ) {
        fileName =
          "Final Report_" +
          record.outputPath.split("\\").pop().split("_").pop();
      } else {
        let name = record.outputPath
          .split(record.outputPath.includes("\\") ? "\\" : "/")
          .pop();
        let index = name.indexOf("_", name.indexOf("_") + 1);
        fileName = "Final Report_" + name.substring(index + 1);
      }

      if (analysisReport) {
        fileName = fileName.replace(".pdf", "_AnalysisReport.pdf");
      }

      localStorage.setItem("uniqueId", record.uniqueId);
      localStorage.setItem("fileName", fileName);

      if (
        user.id === BEN_USER_ID ||
        (user.userType === BETA_USER_TYPE && !isBetaUserExpired())
      ) {
        downloadingFinalReport(record, fileName, analysisReport);
        return;
      }

      if (user.userType === BETA_USER_TYPE && isBetaUserExpired()) {
        setBetaUserExpired(true);
        return;
      }

      checkReportPayment(record.uniqueId)
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data &&
              response.data.isPaid &&
              response.data.status === "Completed"
            ) {
              downloadingFinalReport(record, fileName, analysisReport);
            } else if (
              !response.data.isPaid &&
              response.data.status === "Completed"
            ) {
              setRecordId(record.uniqueId);
              setFileName(fileName[0] + ".pdf");
              setPaymentResponse(true);
            }
          }
        })
        .catch((error) => {});
    }
  };

  const isBetaUserExpired = () => {
    if (user) {
      let date = user?.expiryDate;

      const today = new Date().setHours(0, 0, 0, 0);
      const expiry =
        user.expiryDate === null
          ? today
          : new Date(moment(date).format("MM/DD/YYYY")).setHours(0, 0, 0, 0);

      return today > expiry;
    }
  };

  const downloadingFinalReport = (record, fileName, analysisReport) => {
    setGenratingFile(true);
    downloadReport(record.uniqueId, fileName, analysisReport);
    // loadSSRCount();
  };

  const downloadReport = (id, fileName, analysisReport) => {
    setDownloadModal(true);
    downlaodFile(id, analysisReport)
      .then((response) => {
        if (response.status === 200) {
          downloadedFile(response, fileName);

          setGenratingFile(false);
        } else {
          setGenratingFile(false);
          message.error(
            fetchCustomerMessages()
              ? fetchCustomerMessages()[
                  "If final report is removed from server by any chance (user report dashboard page)"
                ]
              : ""
          );
        }
      })
      .catch((error) => {
        setGenratingFile(false);
        message.error("error", error);
      })
      .finally(() => {
        setDownloadModal(false);
        setDownloadCompletionModal(true);
      });
  };

  const onSearch = (page, pageSize, values) => {
    getSSRFilterCount(
      values.success,
      values.failed,
      values.paid,
      values.unPaid
    ).then((res) => {
      if (res.status === 200) {
        setLoading(true);
        setSSRCount(res.data);
        getSSRFilterData(
          page,
          pageSize,
          values.success,
          values.failed,
          values.paid,
          values.unPaid
        ).then((res) => {
          if (res.status === 200) {
            setData(res.data);
            setLoading(false);
          }
        });
      }
    });
  };

  function daysBetween(startDate, endDate) {
    var millisecondsPerDay = 1000 * 60 * 60 * 24;
    var startDateUTC = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    var endDateUTC = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    return Math.floor((endDateUTC - startDateUTC) / millisecondsPerDay);
  }

  const checkForDate = (record) => {
    const then = new Date(moment(record.creationDate).format("MM/DD/YYYY"));
    const now = new Date();

    let days = daysBetween(then, now);

    if (days > 30) {
      return true;
    } else {
      return false;
    }
  };

  const checkStatus = (record) => {
    return record.status !== "Completed" ? true : false;
  };

  const finalReportButton = (record) => {
    return (
      <div>
        <Tooltip
          title={
            user.id === BEN_USER_ID
              ? "Download Zip File"
              : "Download Final Report"
          }
          placement="left"
        >
          <Button
            shape="round"
            className="ant-button"
            disabled={checkForDate(record) || checkStatus(record)}
          >
            {user.id === BEN_USER_ID ? (
              <FileZipTwoTone
                twoToneColor="#e4003b"
                style={{ fontSize: "17px" }}
                onClick={(e) => {
                  generateFinalReport(record, false);
                }}
              />
            ) : (
              <FilePdfTwoTone
                twoToneColor="#e4003b"
                style={{ fontSize: "17px" }}
                onClick={(e) => {
                  generateFinalReport(record, false);
                }}
              />
            )}
          </Button>
        </Tooltip>
      </div>
    );
  };

  const content = (record) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          padding: "12px 16px",
        }}
      >
        {finalReportButton(record)}
        <div>
          <Tooltip title="Download DayWise Analysis Report" placement="left">
            <Button
              shape="round"
              className="ant-button"
              disabled={checkForDate(record) || checkStatus(record)}
            >
              <FilePdfTwoTone
                twoToneColor="#e4003b"
                style={{ fontSize: "17px" }}
                onClick={(e) => {
                  generateFinalReport(record, true);
                }}
              />
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          Original File Name
        </div>
      ),
      dataIndex: "fileName",
      key: "fileName",
      fixed: "left",
      width: "16%",
      align: "center",
      render: (fileName) => (
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>{fileName ? fileName : "-"}</div>
        </React.Fragment>
      ),
    },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          Run Date & Time (UTC)
        </div>
      ),
      dataIndex: "creationDate",
      width: "8%",
      align: "center",
      render: (creationDate) => (
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>
            {creationDate
              ? moment(creationDate).format("MM/DD/YYYY HH:mm:ss")
              : ""}
          </div>
        </React.Fragment>
      ),
    },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          Email
        </div>
      ),
      dataIndex: "email",
      key: "email",
      width: "10%",
      align: "center",
      render: (email) => (
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>{email ? email : "-"}</div>
        </React.Fragment>
      ),
    },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          CellNinja Report Name
        </div>
      ),
      dataIndex: "outputPath",
      key: "outputPath",
      width: "30%",
      align: "center",
      render: (outputPath) => {
        if (outputPath) {
          let file = outputPath.split("/").pop();
          return (
            <React.Fragment>
              <div style={{ fontSize: "10.5px" }}>{file ? file : ""}</div>
            </React.Fragment>
          );
        }
      },
    },
    {
      title: (
        <div
          style={{
            fontSize: "larger",
            color: "black",
            fontWeight: "500",
            borderBottom: "0.5px solid",
          }}
        >
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "100",
      children: [
        {
          title: (
            <div
              style={{ fontSize: "larger", color: "black", fontWeight: "500" }}
            >
              Report Gen.
            </div>
          ),
          dataIndex: "status",
          key: "status",
          align: "center",
          width: "6%",
          render: (status) => (
            <React.Fragment>
              <div style={{ fontSize: "10.5px" }}>
                {status ? <span>{status}</span> : ""}
              </div>
            </React.Fragment>
          ),
        },
        {
          title: (
            <div
              style={{
                fontSize: "larger",
                color: "black",
                fontWeight: "500",
              }}
            >
              Payment
            </div>
          ),
          dataIndex: "isPaid",
          key: "isPaid",
          align: "center",
          width: "6%",
          render: (isPaid) => (
            <React.Fragment>
              {isPaid ? (
                <CheckCircleOutlined
                  style={{ color: "green", fontSize: "17px" }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ color: "red", fontSize: "17px" }}
                />
              )}
            </React.Fragment>
          ),
        },
      ],
    },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          Download Report
        </div>
      ),
      width: "8%",
      align: "center",
      render: (_, record) => {
        // checkForDate(record);
        // let disable = record.status !== "Completed" ? true : false;
        return user?.userType === "Annual" ? (
          <Popover content={() => content(record)}>
            <Button
              shape="round"
              className="ant-button"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertIcon style={{ width: "16px" }} />
            </Button>
          </Popover>
        ) : (
          finalReportButton(record)
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="reports-view-adjuster">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              marginTop: "5px",
              marginRight: "5px",
              marginLeft: "auto",
              marginBottom: "5px",
            }}
          >
            <Button
              className="refresh-btn"
              onClick={() => loadSSRCount()}
              icon={
                <RetweetOutlined title="Refresh" style={{ fontSize: "20px" }} />
              }
            >
              Refresh
            </Button>
          </div>
        </div>

        <div hidden={paymentModal}>
          <Modal
            open={paymentModal}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h3>Pay and Download Report</h3>
                </div>
              </div>
            }
            destroyOnClose
            width="450px"
            height="200px"
            onCancel={(e) => {
              setPaymentModal(false);
            }}
            footer={null}
          >
            <StripComponent
              fileName={fileName}
              uniqueId={recordId}
              onPaymentDone={() => {
                setPaymentModal(false);
              }}
            />
          </Modal>
        </div>

        <div hidden={paymentResponse}>
          <Modal
            open={paymentResponse}
            width={500}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h3>
                    <b>Payment Required</b>
                  </h3>
                </div>
              </div>
            }
            destroyOnClose
            onCancel={(e) => {
              setPaymentResponse(false);
            }}
            footer={null}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <img alt="alert" src={DOLLARICON} height={90} width={90} />
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <h3>
                  {fetchCustomerMessages()
                    ? fetchCustomerMessages()[
                        "Payment pending dialog in user report page if payment is not done and user want to download final report"
                      ]
                    : ""}
                </h3>
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    height: "40px",
                    backgroundColor: CELLNINJACOLOR,
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setPaymentResponse(false);
                    setPaymentModal(true);
                  }}
                >
                  Proceed to Payment
                </Button>
              </div>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            open={downloadModal}
            width={500}
            footer={null}
            closable={false}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img
                alt="downloadicon"
                src={DOWNLOADICON}
                height={80}
                width={80}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{"Your file is being downloaded. Please wait..."}</h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setDownloadModal(false);
                }}
              >
                OK
              </Button>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            open={downloadCompletionModal}
            width={500}
            footer={null}
            closable={false}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img
                alt="downloadicon"
                src={DOWNLOADICON}
                height={80}
                width={80}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{"Your file has been downloaded successfully."}</h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setDownloadCompletionModal(false);
                }}
              >
                OK
              </Button>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            open={betaUserExpired}
            width={500}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h3>
                    <b>Access Restricted</b>
                  </h3>
                </div>
              </div>
            }
            destroyOnClose
            onCancel={(e) => {
              setPaymentResponse(false);
            }}
            footer={null}
          >
            <div style={{ textAlign: "center" }}>
              <img alt="alert" src={ALERTICON} height={90} width={90} />
            </div>
            <div>
              <br />
              <div style={{ textAlign: "center" }}>
                <h3>
                  Your trial period has expired, and you are not permitted to
                  download the final report. Contact us at info@cell-ninja.com
                  for assistance.
                </h3>
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    height: "40px",
                    backgroundColor: CELLNINJACOLOR,
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setBetaUserExpired(false);
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </Modal>
        </div>

        <Form component={false} name="" style={{ border: ".5px red" }}>
          <Spin
            style={{ color: "#e4003b" }}
            spinning={genratingFile}
            tip="Downloading File..."
            delay={200}
          >
            <Table
              scroll={{
                x: 1500,
                y: 405,
              }}
              style={{
                border: "1px solid lightgrey",
                // height: "56vh",
                width: "auto",
                overflow: "auto",
              }}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              onChange={(pagination, filters, sorter, extra) => {
                setPageNumber(1);
                setOrderBy(sorter.order);
                setSortBy(sorter.field);
                loadSSRCount(1, 10, sorter.field, sorter.order);
              }}
            />
          </Spin>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Pagination
              current={pageNumber}
              total={ssrCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} Records`
              }
              showSizeChanger={true}
              onChange={onShowSizeChange}
            />
          </div>
          <br />
        </Form>
      </div>
      <div style={{ display: "flex" }}>
        <p
          style={{
            fontSize: "15px",
            marginLeft: "30%",
            marginRight: "25%",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          <span style={{ color: "#e4003b" }}>NOTE: </span>
          Your original file remains stored for 30 days for your convenience and
          is then deleted. You can download a copy of your CellNinja report from
          the options on the right.
        </p>
        <a href="/contactus">
          <Button className="refresh-btn">Suggestions</Button>
        </a>
      </div>
    </React.Fragment>
  );
};
export default UserReports;
