/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Form, Input, message, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { addUser, editUser, getRoles } from "../../../apis/settingsapi";
import {
  ALPHABETICAL_INPUTS,
  ANNUAL_USER_TYPE,
  BASIC_USER_TYPE,
  BETA_USER_TYPE,
  PREMIUM_USER_TYPE,
} from "../../../utils/constants";
import moment from "moment";
import dayjs from "dayjs";
import "./style.scss";

const { RangePicker } = DatePicker;
const { Option } = Select;

const UserForm = (props) => {
  const [rolesOptions, setRolesOptions] = useState([]);
  const [required, setRequired] = useState(false);
  const [userEnable, setUserEnable] = useState(0);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isBetaUser, setIsBetaUser] = useState(true);
  const [expireDate, setExpireDate] = useState(null);

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    labelAlign: "right",
    name: "user-form",
  };

  useEffect(() => {
    if (props.user && props.action === "Edit") {
      const values = props.user;
      const expiryDate = values.expiryDate ? dayjs(values.expiryDate) : "";
      setExpireDate(expiryDate);
      form.setFieldsValue({
        ...values,
        role_ids: (values.roles || []).map((p) => p.id),
        enabled: setUserEnable(values.enabled),
      });
      setRequired(true);
    } else {
      form.resetFields();
      setExpireDate(null);
    }
  }, [props.action, props.user]);

  const handleSubmit = (e) => {
    if (props.action === "Add") {
      e.preventDefault();
      form.validateFields().then((values) => {
        setIsLoading(true);
        const { role_ids = [], enabled = userEnable, ...otherValues } = values;
        addUser({
          roles: role_ids.map((rId) => ({ id: rId })),
          enabled: userEnable,
          expiryDate: moment(values.expiryDate).format("MM/DD/YYYY"),
          ...otherValues,
        })
          .then((response) => {
            if (response.status === 200) {
              props.closeModal(false);
              setIsLoading(false);
              props.newUser();
              form.resetFields();
              message.success(response.data.message);
            } else if (response.response.status === 500) {
              message.error(response.response.data.message);
              setIsLoading(false);
            } else {
              message.error(response.response.data.message);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            message.error(error.response.data.message, 5);
            setIsLoading(false);
          });
      });
    } else if (props.action === "Edit") {
      e.preventDefault();
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          values.enabled = userEnable;
          const { role_ids = [], ...otherValues } = values;

          const newUser = props.user;
          newUser.roles = role_ids.map((rId) => ({ id: rId }));
          newUser.name = values.name;
          newUser.userType = values.userType;
          newUser.enabled = values.enabled;
          newUser.email = values.email;
          newUser.username = values.username;
          newUser.organization = values.organization;
          newUser.expiryDate = values.expiryDate;

          editUser(newUser)
            .then((response) => {
              if (response.status === 200) {
                props.closeModal(false);
                setIsLoading(false);
                props.newUser();
                form.resetFields();
                message.success(response.data.message);
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 500) {
                message.error(error.response.data.message);
              } else {
                message.error("An unexpected error occurred.");
              }
              setIsLoading(false);
            });
        })
        .catch((e) => {
          message.error(e.response.data.message, 5);
          setIsLoading(false);
        });
    }
  };

  const fetchRoles = async () => {
    const response = await getRoles();
    if (response.status === 200) {
      setRolesOptions(response.data);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [props.action, props.user]);

  const onChange = (checked) => {
    checked === false ? setUserEnable(0) : setUserEnable(1);
  };

  function disabledDate(current) {
    // Disable dates before today
    return current && current < moment().startOf("day");
  }
  const handleChange = (value) => {
    if (value === BETA_USER_TYPE) {
      setIsBetaUser(true);
    }
  };

  const handleDateChange = (date) => {
    setExpireDate(date);
    form.setFieldValue("expiryDate", date);
  };

  const addDays = (date, days) => {
    return moment(date).add(days, "days");
  };

  const handleAdd30Days = () => {
    if (expireDate) {
      const newDate = addDays(expireDate, 30);
      form.setFieldValue("expiryDate", newDate);
      setExpireDate(newDate);
    } else {
      const newDate = addDays(moment(), 30);
      form.setFieldValue("expiryDate", newDate);
      setExpireDate(newDate);
    }
  };

  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              pattern: ALPHABETICAL_INPUTS,
              type: "string",
              message: "Please enter valid name",
            },
          ]}
        >
          <Input maxLength={512} />
        </Form.Item>

        <Form.Item
          name="role_ids"
          label="Roles"
          rules={[
            {
              required: true,
              message: "Please select permissions from list",
            },
          ]}
        >
          <Select showSearch mode="multiple">
            {rolesOptions
              ? rolesOptions.map((role) => {
                  return (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  );
                })
              : ""}
          </Select>
        </Form.Item>

        <Form.Item
          name="userType"
          label="Subscription Type"
          rules={[
            {
              required: true,
              message: "Please select user type from list",
            },
          ]}
        >
          <Select onChange={handleChange}>
            <Option value={BETA_USER_TYPE}>{BETA_USER_TYPE}</Option>
            <Option value={BASIC_USER_TYPE}>{BASIC_USER_TYPE}</Option>
            <Option value={PREMIUM_USER_TYPE}>{PREMIUM_USER_TYPE}</Option>
            <Option value={ANNUAL_USER_TYPE}>{ANNUAL_USER_TYPE}</Option>
          </Select>
        </Form.Item>

        <Form.Item name="organization" label="Organization">
          <Input />
        </Form.Item>

        <Form.Item name="enabled" label="Enable">
          <Switch
            style={{ float: "left" }}
            defaultChecked
            onChange={onChange}
            checked={userEnable}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid email",
            },
          ]}
        >
          <Input type="email" maxLength={64} />
        </Form.Item>

        <Form.Item
          name="username"
          label="Login ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="login" maxLength={20} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          hidden={required}
          rules={[
            {
              required: !required,
              message: "Please input password",
            },
            {
              pattern:
                "^(?=.*\\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*\\W.*).{10,}$",
              message:
                "Password must be at least 10 characters long, with one uppercase, one lowercase, and one special character.",
            },
          ]}
          hasFeedback
        >
          <Input.Password disabled={required} maxLength={60} minLength={8} />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          hidden={required}
          rules={[
            {
              required: !required,
              message: "Please confirm your password!",
            },

            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password disabled={required} minLength={8} />
        </Form.Item>

        <Form.Item label="Expire Date" name="expiryDate" hidden={!isBetaUser}>
          <DatePicker
            disabledDate={disabledDate}
            value={expireDate}
            onChange={handleDateChange}
            format="MM/DD/YYYY"
          />
          <Button className="add-30-days-btn" onClick={handleAdd30Days}>
            Add 30 Days
          </Button>
        </Form.Item>
      </Form>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <Button
            loading={isLoading}
            type="primary"
            key="submit"
            htmlType="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
